import _frontend from "./dist/frontend";
export { _frontend as default };
export const __esModule = _frontend.__esModule,
      useId = _frontend.useId,
      Menu = _frontend.Menu,
      MenuList = _frontend.MenuList,
      MenuButton = _frontend.MenuButton,
      MenuLink = _frontend.MenuLink,
      MenuItem = _frontend.MenuItem,
      useRect = _frontend.useRect,
      useTooltip = _frontend.useTooltip,
      TooltipPopup = _frontend.TooltipPopup,
      checkStyles = _frontend.checkStyles,
      wrapEvent = _frontend.wrapEvent,
      assignRef = _frontend.assignRef,
      __DEBUG__ = _frontend.__DEBUG__,
      TREE_OPERATION_ADD = _frontend.TREE_OPERATION_ADD,
      TREE_OPERATION_REMOVE = _frontend.TREE_OPERATION_REMOVE,
      TREE_OPERATION_REORDER_CHILDREN = _frontend.TREE_OPERATION_REORDER_CHILDREN,
      TREE_OPERATION_UPDATE_TREE_BASE_DURATION = _frontend.TREE_OPERATION_UPDATE_TREE_BASE_DURATION,
      LOCAL_STORAGE_FILTER_PREFERENCES_KEY = _frontend.LOCAL_STORAGE_FILTER_PREFERENCES_KEY,
      SESSION_STORAGE_LAST_SELECTION_KEY = _frontend.SESSION_STORAGE_LAST_SELECTION_KEY,
      SESSION_STORAGE_RECORD_CHANGE_DESCRIPTIONS_KEY = _frontend.SESSION_STORAGE_RECORD_CHANGE_DESCRIPTIONS_KEY,
      SESSION_STORAGE_RELOAD_AND_PROFILE_KEY = _frontend.SESSION_STORAGE_RELOAD_AND_PROFILE_KEY,
      LOCAL_STORAGE_SHOULD_PATCH_CONSOLE_KEY = _frontend.LOCAL_STORAGE_SHOULD_PATCH_CONSOLE_KEY,
      LOCAL_STORAGE_TRACE_UPDATES_ENABLED_KEY = _frontend.LOCAL_STORAGE_TRACE_UPDATES_ENABLED_KEY,
      PROFILER_EXPORT_VERSION = _frontend.PROFILER_EXPORT_VERSION,
      CHANGE_LOG_URL = _frontend.CHANGE_LOG_URL,
      UNSUPPORTED_VERSION_URL = _frontend.UNSUPPORTED_VERSION_URL,
      COMFORTABLE_LINE_HEIGHT = _frontend.COMFORTABLE_LINE_HEIGHT,
      COMPACT_LINE_HEIGHT = _frontend.COMPACT_LINE_HEIGHT,
      RegistryContext = _frontend.RegistryContext,
      createResource = _frontend.createResource,
      invalidateResources = _frontend.invalidateResources,
      printElement = _frontend.printElement,
      printOwnersList = _frontend.printOwnersList,
      printStore = _frontend.printStore,
      sanitizeForParse = _frontend.sanitizeForParse,
      smartParse = _frontend.smartParse,
      smartStringify = _frontend.smartStringify,
      HooksTreeView = _frontend.HooksTreeView,
      InnerHooksTreeView = _frontend.InnerHooksTreeView,
      InspectedElementContext = _frontend.InspectedElementContext,
      InspectedElementContextController = _frontend.InspectedElementContextController,
      NativeStyleContext = _frontend.NativeStyleContext,
      NativeStyleContextController = _frontend.NativeStyleContextController,
      OwnersListContext = _frontend.OwnersListContext,
      OwnersListContextController = _frontend.OwnersListContextController,
      TreeDispatcherContext = _frontend.TreeDispatcherContext,
      TreeStateContext = _frontend.TreeStateContext,
      TreeContextController = _frontend.TreeContextController,
      ModalDialog = _frontend.ModalDialog,
      ModalDialogContext = _frontend.ModalDialogContext,
      ModalDialogContextController = _frontend.ModalDialogContextController,
      getCommitTree = _frontend.getCommitTree,
      invalidateCommitTrees = _frontend.invalidateCommitTrees,
      getChartData = _frontend.getChartData,
      invalidateChartData = _frontend.invalidateChartData,
      ProfilerContext = _frontend.ProfilerContext,
      ProfilerContextController = _frontend.ProfilerContextController,
      barWidthThreshold = _frontend.barWidthThreshold,
      interactionCommitSize = _frontend.interactionCommitSize,
      interactionLabelWidth = _frontend.interactionLabelWidth,
      maxBarWidth = _frontend.maxBarWidth,
      minBarWidth = _frontend.minBarWidth,
      prepareProfilingDataFrontendFromBackendAndStore = _frontend.prepareProfilingDataFrontendFromBackendAndStore,
      prepareProfilingDataFrontendFromExport = _frontend.prepareProfilingDataFrontendFromExport,
      prepareProfilingDataExport = _frontend.prepareProfilingDataExport,
      getGradientColor = _frontend.getGradientColor,
      formatDuration = _frontend.formatDuration,
      formatPercentage = _frontend.formatPercentage,
      formatTime = _frontend.formatTime,
      scale = _frontend.scale,
      SettingsContext = _frontend.SettingsContext,
      SettingsContextController = _frontend.SettingsContextController,
      SettingsModalContext = _frontend.SettingsModalContext,
      SettingsModalContextController = _frontend.SettingsModalContextController,
      BridgeContext = _frontend.BridgeContext,
      StoreContext = _frontend.StoreContext,
      ContextMenuContext = _frontend.ContextMenuContext,
      useEditableValue = _frontend.useEditableValue,
      useIsOverflowing = _frontend.useIsOverflowing,
      useLocalStorage = _frontend.useLocalStorage,
      useModalDismissSignal = _frontend.useModalDismissSignal,
      useSubscription = _frontend.useSubscription,
      alphaSortEntries = _frontend.alphaSortEntries,
      createRegExp = _frontend.createRegExp,
      getMetaValueLabel = _frontend.getMetaValueLabel,
      serializeDataForCopy = _frontend.serializeDataForCopy,
      serializeHooksForCopy = _frontend.serializeHooksForCopy,
      downloadFile = _frontend.downloadFile,
      truncateText = _frontend.truncateText,
      meta = _frontend.meta,
      dehydrate = _frontend.dehydrate,
      fillInPath = _frontend.fillInPath,
      hydrate = _frontend.hydrate,
      VariableSizeGrid = _frontend.VariableSizeGrid,
      VariableSizeList = _frontend.VariableSizeList,
      FixedSizeGrid = _frontend.FixedSizeGrid,
      FixedSizeList = _frontend.FixedSizeList,
      areEqual = _frontend.areEqual,
      shouldComponentUpdate = _frontend.shouldComponentUpdate,
      localStorageGetItem = _frontend.localStorageGetItem,
      localStorageRemoveItem = _frontend.localStorageRemoveItem,
      localStorageSetItem = _frontend.localStorageSetItem,
      sessionStorageGetItem = _frontend.sessionStorageGetItem,
      sessionStorageRemoveItem = _frontend.sessionStorageRemoveItem,
      sessionStorageSetItem = _frontend.sessionStorageSetItem,
      ElementTypeClass = _frontend.ElementTypeClass,
      ElementTypeContext = _frontend.ElementTypeContext,
      ElementTypeFunction = _frontend.ElementTypeFunction,
      ElementTypeForwardRef = _frontend.ElementTypeForwardRef,
      ElementTypeHostComponent = _frontend.ElementTypeHostComponent,
      ElementTypeMemo = _frontend.ElementTypeMemo,
      ElementTypeOtherOrUnknown = _frontend.ElementTypeOtherOrUnknown,
      ElementTypeProfiler = _frontend.ElementTypeProfiler,
      ElementTypeRoot = _frontend.ElementTypeRoot,
      ElementTypeSuspense = _frontend.ElementTypeSuspense,
      ElementTypeSuspenseList = _frontend.ElementTypeSuspenseList,
      ComponentFilterElementType = _frontend.ComponentFilterElementType,
      ComponentFilterDisplayName = _frontend.ComponentFilterDisplayName,
      ComponentFilterLocation = _frontend.ComponentFilterLocation,
      ComponentFilterHOC = _frontend.ComponentFilterHOC,
      getDisplayName = _frontend.getDisplayName,
      getUID = _frontend.getUID,
      utfDecodeString = _frontend.utfDecodeString,
      utfEncodeString = _frontend.utfEncodeString,
      printOperationsArray = _frontend.printOperationsArray,
      getDefaultComponentFilters = _frontend.getDefaultComponentFilters,
      getSavedComponentFilters = _frontend.getSavedComponentFilters,
      saveComponentFilters = _frontend.saveComponentFilters,
      getAppendComponentStack = _frontend.getAppendComponentStack,
      setAppendComponentStack = _frontend.setAppendComponentStack,
      separateDisplayNameAndHOCs = _frontend.separateDisplayNameAndHOCs,
      shallowDiffers = _frontend.shallowDiffers,
      getInObject = _frontend.getInObject,
      setInObject = _frontend.setInObject,
      getDataType = _frontend.getDataType,
      getDisplayNameForReactElement = _frontend.getDisplayNameForReactElement,
      formatDataForPreview = _frontend.formatDataForPreview,
      MESSAGE_TYPE_GET_SAVED_PREFERENCES = _frontend.MESSAGE_TYPE_GET_SAVED_PREFERENCES,
      MESSAGE_TYPE_SAVED_PREFERENCES = _frontend.MESSAGE_TYPE_SAVED_PREFERENCES,
      initialize = _frontend.initialize;